export type FooterLayout = 'withSidebar' | 'centerProse' | 'centerCheckout' | 'floating';

export default function useFooterState() {
    const isEnabled = useState<any>('footer_is_enabled', () => true);
    const forwardCallback = useState<any>('footer_forward_callback', () => undefined);
    const forwardLabel = useState('footer_forward_label', () => '');
    const backwardCallback = useState<any>('footer_backward_callback', () => undefined);
    const backwardLabel = useState('footer_backward_label', () => '');
    const layout = useState<FooterLayout>('footer_layout', () => 'withSidebar');
    const isPriceVisible = useState<boolean>('footer_is_price_visible', () => true);
    const canProceed = useState<boolean>('footer_can_proceed', () => false);
    const canGoBack = useState<boolean>('footer_can_go_back', () => true);
    const isProceedDisabledVisually = useState<boolean>('footer_is_proceed_disabled_visually', () => false);

    return {
        isEnabled,
        forwardLabel,
        forwardCallback,
        backwardCallback,
        backwardLabel,
        layout,
        isPriceVisible,
        canProceed,
        canGoBack,
        isProceedDisabledVisually,
    };
}
